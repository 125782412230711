try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('select2');
    require('datatables.net-bs4');
    require('datatables.net-buttons-bs4');
    window.moment = require('moment');
    moment().format();
    require('tempusdominus-bootstrap-4');
    window.Swal = require('sweetalert2');
    require('trumbowyg');
    require('jquery-validation');
    require('jquery-ui-sortable-npm');
    window.toastr = require('toastr');
    require('ekko-lightbox');
    window.bsCustomFileInput = require('bs-custom-file-input');
    bsCustomFileInput.init();
    clipboard = require("clipboard-polyfill");
    require('jquery-ui-sortable-npm');
    window.Huebee = require('huebee');
    window.intlTelInput = require('intl-tel-input');
    window.Chart = require('chart.js');
} catch (e) {}

$.fn.select2.defaults.set( "theme", "bootstrap4" );

$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    locale: 'fr_FR',
    icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-caret-up',
        down: 'fa fa-caret-down',
        previous: 'fa fa-caret-left',
        next: 'fa fa-caret-right',
        today: 'fa fa-calendar',
        clear: 'fa fa-trash',
        close: 'fa fa-times'
    },
    useCurrent: false,
    showClear: true,
    showClose: true,
    keepOpen: false
});

$.extend(true, $.fn.dataTable.defaults, {
    language: {
        "sProcessing":     "Traitement en cours...",
        "sSearch":         "Rechercher&nbsp;:",
        "sLengthMenu":     "Afficher _MENU_ &eacute;l&eacute;ments",
        "sInfo":           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        "sInfoEmpty":      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
        "sInfoFiltered":   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        "sInfoPostFix":    "",
        "sLoadingRecords": "Chargement en cours...",
        "sZeroRecords":    "Aucun &eacute;l&eacute;ment &agrave; afficher",
        "sEmptyTable":     "Aucune donn&eacute;e disponible dans le tableau",
        "oPaginate": {
            "sFirst":      "Premier",
            "sPrevious":   "Pr&eacute;c&eacute;dent",
            "sNext":       "Suivant",
            "sLast":       "Dernier"
        },
        "oAria": {
            "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
            "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
        },
        "select": {
                "rows": {
                    _: "%d lignes séléctionnées",
                    0: "Aucune ligne séléctionnée",
                    1: "1 ligne séléctionnée"
                }
        }
    }
});


toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
}

$.validator.setDefaults({
    errorElement: 'span',
    errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-group').after().append(error);
    },
    highlight: function (element, errorClass, validClass) {
        $(element).addClass('is-invalid');
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('is-invalid');
    },
});

$.extend( $.validator.messages, {
    required: "Ce champ est obligatoire.",
    remote: "Veuillez corriger ce champ.",
    email: "Veuillez fournir une adresse électronique valide.",
    url: "Veuillez fournir une adresse URL valide.",
    date: "Veuillez fournir une date valide.",
    dateISO: "Veuillez fournir une date valide (ISO).",
    number: "Veuillez fournir un numéro valide.",
    digits: "Veuillez fournir seulement des chiffres.",
    creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
    equalTo: "Veuillez fournir encore la même valeur.",
    notEqualTo: "Veuillez fournir une valeur différente, les valeurs ne doivent pas être identiques.",
    extension: "Veuillez fournir une valeur avec une extension valide.",
    maxlength: $.validator.format( "Veuillez fournir au plus {0} caractères." ),
    minlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
    rangelength: $.validator.format( "Veuillez fournir une valeur qui contient entre {0} et {1} caractères." ),
    range: $.validator.format( "Veuillez fournir une valeur entre {0} et {1}." ),
    max: $.validator.format( "Veuillez fournir une valeur inférieure ou égale à {0}." ),
    min: $.validator.format( "Veuillez fournir une valeur supérieure ou égale à {0}." ),
    step: $.validator.format( "Veuillez fournir une valeur multiple de {0}." ),
    maxWords: $.validator.format( "Veuillez fournir au plus {0} mots." ),
    minWords: $.validator.format( "Veuillez fournir au moins {0} mots." ),
    rangeWords: $.validator.format( "Veuillez fournir entre {0} et {1} mots." ),
    letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
    alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
    lettersonly: "Veuillez fournir seulement des lettres.",
    nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
    ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
    integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
    vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
    dateITA: "Veuillez fournir une date valide.",
    time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
    phoneUS: "Veuillez fournir un numéro de téléphone valide.",
    phoneUK: "Veuillez fournir un numéro de téléphone valide.",
    mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
    strippedminlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
    email2: "Veuillez fournir une adresse électronique valide.",
    url2: "Veuillez fournir une adresse URL valide.",
    creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
    ipv4: "Veuillez fournir une adresse IP v4 valide.",
    ipv6: "Veuillez fournir une adresse IP v6 valide.",
    require_from_group: "Veuillez fournir au moins {0} de ces champs.",
    nifES: "Veuillez fournir un numéro NIF valide.",
    nieES: "Veuillez fournir un numéro NIE valide.",
    cifES: "Veuillez fournir un numéro CIF valide.",
    postalCodeCA: "Veuillez fournir un code postal valide."
});

