require('./bootstrap');

$(document).ready( function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('#acceptCookies').on('click', function() {
        $.post( "/cookies/accept", {}).done(function( data ) {
            $('#cookie').addClass('d-none');
        });
    });

    $('.datatable').DataTable({
        processing: true,
        serverSide: false,
        searchDelay: 1000,
        language: {
            url: '/js/datatables.french.json'
        }
    });

    $('.table-users').DataTable({
        order: [[ 3, 'asc' ]],
    });

    $('.table-registrations').DataTable({
        order: [[ 8, 'desc' ]],
    });

    $('.table-accounts').DataTable({
        order: [[ 0, 'asc' ]],
    });

    $('.table-projects').DataTable({
        order: [[ 0, 'des' ]],
    });

    $('.table-invoices').DataTable({
        order: [[ 0, 'desc' ]],
    });

    $('.table-questions').DataTable({
        order: [[ 0, 'asc' ]],
    });

    $('.table-categories').DataTable({
        order: [[ 0, 'asc' ]],
    });

    $('.select2').select2();

    $('.select2tags').select2({
        tags: true
    });

    $.trumbowyg.svgPath = '/images/trumbowyg.svg';

    $('textarea.wysiwyg').trumbowyg({
        btns: [
            ['viewHTML'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['link'],
            // ['insertImage'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
            ['fullscreen']
        ],
        autogrowOnEnter: true,
        lang: 'fr',
        semantic: true,
    });

    $('.destroy').on('click', function (event) {
        event.preventDefault();
        Swal.fire({
            title: 'Supprimer',
            text: "Souhaitez-vous supprimer définitivement cet élément ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                $(this).next('form').submit();
            }
        });
    });

    $('#opened_at_datetime').datetimepicker({
        date: moment($('#opened_at').data('value')).toDate()
    });
    $('#closed_at_datetime').datetimepicker({
        date: moment($('#closed_at').data('value')).toDate()
    });
    $("#opened_at_datetime").on("change.datetimepicker", function (e) {
        $('#closed_at_datetime').datetimepicker('minDate', e.date);
    });
    $("#closed_at_datetime").on("change.datetimepicker", function (e) {
        $('#opened_at_datetime').datetimepicker('maxDate', e.date);
    });

    $(".questions-sortable").sortable({
        cursor: 'move',
        update: function( event, ui ) {
            var serializedData = $(".sortable").sortable('toArray', { attribute: "data-question-id" });
            $.ajax({
                method: 'POST',
                async: true,
                url: "/projects/{{ $project->id }}/reorderQuestions",
                data: { sortable: serializedData },
                error: function(data) { return false; },
                success: function(data) {
                    toastr.success('Ordre enregistré avec succès')
                    return false;
                }
            });
        }
    }).disableSelection();

    $('#project-create-form').validate();

    $('#question-form').validate();

    $('#notification-create-form').validate();

    $('#notification-edit-form').validate();

    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    $('#passwordSuggestion').on('click', function (event) {
        $(this).parent().prev('input').val( $(this).text() );
        return false;
    });

    $('.toClipboard').on('click', function (event) {
        clipboard.writeText( $(this).data('clipboard') );
        toastr.success('Copié dans le presse-papier');
        return false;
    });

    $('input[name=toggleAll]').on('click', function (event) {
        $('#registrations-search-results tbody :checkbox').trigger( "click" );
    });

    $(document).on('click', '#registrations-search-results tbody :checkbox', function(event) {
        if ($('#registrations-search-results tbody :checkbox:checked').length > 0) {
            $('#destroyAll').removeClass('d-none');
        } else {
            $('#destroyAll').addClass('d-none');
        }
    });

    $('#destroyAll').on('click', function (event) {
        event.preventDefault();
        Swal.fire({
            title: 'Supprimer',
            text: "Souhaitez-vous supprimer définitivement ces éléments ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                $(this).parent('form').submit();
            }
        });
    });

    $('.color-input').each( function( i, elem ) {
        var hueb = new Huebee( elem, {
            notation: 'hex',
            saturations: 1,
            customColors: [
                '#009DAD',
                '#3bbac9',
                '#733f96',
                '#DC911B',
                '#3C005A',
                '#733F96',
                '#C3965A',
                '#D0D0D0',
                '#F18F01',
                '#B2184A',
                '#D4194C',
                '#FFE030',
                '#FFD718',
                '#EE7658',
                '#E05747',
                '#c83386',
                '#C5669F',
                '#3c005a',
                '#009FE3',
                '#50A9DF',
                '#FFCC00',
                '#3BBAC9',
            ]
        });
    });

});
